import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import { preferenceService } from './service/preference-service';
import Hotjar from '@hotjar/browser';

const siteId = 5206519;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

axios.interceptors.request.use(
  (successfulReq) => {
    if (preferenceService.token()) {
      successfulReq.headers['Authorization'] = `Bearer ${preferenceService.token()}`
    }
    return successfulReq;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {

    if (error.response && error.response.status === 401) {
      preferenceService.clearLocal();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <Router basename={'/'}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
